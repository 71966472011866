<template>
  <div class="row">
    <div class="col-md-12 mt-2">
      <div class="card">
        <b-row v-if="isError" class="justify-content-center mt-2">
          <b-col lg="10">
            <b-alert variant="danger" show dismissible>
              Opps .. Something is wrong
            </b-alert>
          </b-col>
        </b-row>
        <div class="card-body">
          <div class="form-group row justify-content-center">
            <div class="col-sm-5">
              <input
                type="text"
                class="form-control"
                placeholder="type item name..."
                @input="debounceSearch"
              />
              <span v-if="typing"> <em> searching... </em> </span>
            </div>
            <div class="col-sm-1">
              <router-link
                class="btn btn-block btn-primary"
                :to="{ path: 'brand-ax/create' }"
              >
                <i class="fa fa-plus"></i>
              </router-link>
            </div>
            <div class="col-sm-1">
              <router-link
                class="btn btn-block btn-primary"
                :to="{ path: 'bulk-upload-subscription' }"
              >
                <i class="fa fa-upload"></i>
              </router-link>
            </div>
          </div>
          <div class="row justify-content-end">
            <label for="" class="col-sm-auto">Total : </label>
            <strong class="col-sm-auto">{{ formatNumber(totalRows) }}</strong>
          </div>
          <b-table
            sticky-header="500px"
            :items="brandAx"
            :fields="fields"
            :busy="isLoading"
            :isLoading="isLoading"
            show-empty
            responsive="xl"
            hover
            sort-icon-left
          >
            <template #cell(No)="row">
              {{ (currentPage - 1) * perPage + (row.index + 1) }}.
            </template>

            <template #cell(name)="row">
              <div style="width: 250px">
                <router-link :to="{ path: 'brand-ax/edit/' + row.item.id }">
                  {{ row.item.name }}
                </router-link>
              </div>
            </template>

            <template #cell(vendor)="row">
              <div style="width: 200px">
                <div v-if="row.item.vendor">
                  <router-link
                    :to="{ path: '/vendor/detail/' + row.item.vendor_id }"
                  >
                    {{ row.item.vendor }}
                  </router-link>
                </div>
                <div v-else>-</div>
              </div>
            </template>

            <template #cell(vendor_id_ax)="row">
              <div style="width: 150px">
                {{ row.item.vendor_id_ax ? row.item.vendor_id_ax : '-' }}
              </div>
            </template>

            <template #cell(created)="row">
              <div style="width: 250px">
                {{ row.item.created ? formatDate(row.item.created) : '-' }}
              </div>
            </template>

            <template #cell(modified)="row">
              <div style="width: 250px">
                {{ row.item.modified ? formatDate(row.item.modified) : '-' }}
              </div>
            </template>

            <template v-slot:table-busy>
              <div class="text-center text-dark my-2">
                <b-spinner
                  class="align-middle"
                  variant="dark"
                  type="grow"
                  label="Loading .."
                >
                </b-spinner>
                Loading ..
              </div>
            </template>
          </b-table>
        </div>
        <b-row class="justify-content-start ml-1">
          <b-col lg="3">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              aria-controls="my-table"
            />
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';
export default {
  name: 'BrandAX',
  data() {
    return {
      currentPage: 1,
      perPage: 100,
      filter: {},
      isLoadingSearch: false,
      items_search: [],
      message: '',
      typing: null,
      fields: [
        { key: 'No', label: 'No.' },
        { key: 'name', label: 'Name' },
        { key: 'subs_idax', label: 'Subscription IDAX' },
        { key: 'vendor', label: 'Vendor' },
        { key: 'vendor_id_ax', label: 'Vendor IDAX' },
        { key: 'created', label: 'Created' },
        { key: 'modified', label: 'Modified' },
      ],
    };
  },
  watch: {
    currentPage: function() {
      this.getBrandAx();
    },
    $route: {
      immediate: true,
      handler(to) {
        document.title =
          to.meta.title ||
          'Brand AX | Content Management System Ebooks Gramedia.com';
      },
    },
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.brandAx.isLoading,
      isError: (state) => state.brandAx.isError,
      totalRows: (state) => state.brandAx.totalRows,
      brandAx: (state) => state.brandAx.items,
    }),
  },
  mounted() {
    this.getBrandAx();
  },
  methods: {
    ...mapActions('brandAx', ['fetchAll']),

    getBrandAx() {
      let payload = {
        page: this.currentPage,
        limit: this.perPage,
      };
      if (this.message) payload.search = this.message;
      this.fetchAll(payload);
    },

    debounceSearch(event) {
      this.typing = 'You are typing';
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.typing = null;
        this.message = event.target.value;
        this.fetchAll({
          search: this.message,
          page: 1,
          limit: this.perPage,
        });
      }, 600);
    },

    formatDate(tgl) {
      return new Date(tgl).toString();
    },

    formatNumber(value) {
      if (value) {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
      }
      return '0';
    },
  },
};
</script>
